<template>
  <div class="ueex-home-page">
    <div class="home-content-one">
      <div class="content">
        <div class="left">
          <div class="home-sign">
            <div class="top">
              {{
                translateTitle("Trade ahead on the rapidly growing exchange")
              }}
            </div>
            <!-- <div class="middle">
              {{ translateTitle("And Trading Platform") }}
            </div> -->
          </div>
          <div class="home-sign V2">
            <span>
              {{ translateTitle("Secure") }}
            </span>
            |
            <span>
              {{ translateTitle("Stable") }}
            </span>
            |
            <span>
              {{ translateTitle("Superior") }}
            </span>
          </div>
          <div class="dong-show">
            <div class="regsiter-content-top" v-show="!userInfoShow">
              <input
                v-model="resiterVal"
                type="text"
                :placeholder="translateTitle('输入邮箱/手机号码')"
              />
            </div>
            <span
              @click="onResiter(2)"
              class="goRegsiter theme-btn"
              v-show="!userInfoShow"
              >{{ translateTitle("注册") }}</span
            >
            <span
              class="go-trade theme-btn"
              @click="goTrade"
              v-show="userInfoShow"
              >{{ translateTitle("Trade Now") }}</span
            >
          </div>
        </div>
        <div class="right" style="cursor: pointer">
          <img src="../../assets/img/ueex/home/one.png" />
        </div>
      </div>
    </div>
    <div class="home-content-banner">
      <div class="content">
        <div class="left">
          <swiper
            class="swiper-banner"
            :pagination="{ clickable: true }"
            :options="swiperOptionTop"
          >
            <swiper-slide
              class="slide"
              v-for="(item, index) of imgList"
              :key="index"
              ><img
                class="swiper-img"
                @click="jumpToUrlV2(item)"
                :src="
                  item.showtype ? item.src : item.language['img_url_' + language]
                "
                :data-href="
                  item.showtype
                    ? ''
                    : item.language
                    ? item.language[`url_${language}`]
                    : item.param.url
                "
                alt=""
            /></swiper-slide>
            <div
              class="swiper-pagination"
              slot="pagination"
            ></div>
            <div
              class="swiper-button-prev"
              v-show="imgList.length > 1"
              slot="button-prev"
            ></div>
            <div
              class="swiper-button-next"
              v-show="imgList.length > 1"
              slot="button-next"
            ></div>
          </swiper>
        </div>
        <div class="right">
          <div class="banner-title">
            <div class="title">{{ translateTitle("Notification") }}</div>
            <div @click="onClickMore" class="more">
              <span>{{ translateTitle("See More") }}</span>
              <i class="iconfont icongengduo"></i>
            </div>
          </div>
          <div class="banner-text">
            <template v-for="(item,index) in articlesList">
              <div v-if="index<=4" class="item"  :key="item.html_url">
                <i class="iconfont iconxiaoxi"></i>
                <a :href="item.html_url" target="_blank">
                  <span class="article-title">
                    {{ item.title || "--" }}</span
                  >
                </a>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="home-content-growing">
      <div class="content">
        <div class="growing-title">
          {{ translateTitle("UEEx is growing and expanding") }}
        </div>
        <div class="growing-mid">
          <div class="item">
            <div class="mid-title">21M +</div>
            <div class="mid-text">
              {{ translateTitle("24H trading volume") }}
            </div>
          </div>
          <div class="item">
            <div class="mid-title">30K +</div>
            <div class="mid-text">
              {{ translateTitle("30D trading volume") }}
            </div>
          </div>
        </div>
        <div class="growing-btm">
          <div class="item">
            <div class="btm-title">110K +</div>
            <div class="btm-text">
              {{ translateTitle("Serviced countries") }}
            </div>
          </div>
          <div class="item">
            <div class="btm-title">1 {{ translateTitle("Million") }} +</div>
            <div class="btm-text">
              {{ translateTitle("Number of Users") }}
            </div>
          </div>
          <div class="item">
            <div class="btm-title">230 +</div>
            <div class="btm-text">
              {{ translateTitle("Trading Pairs") }}
            </div>
          </div>
          <div class="item">
            <div class="btm-title">24/7</div>
            <div class="btm-text">
              {{ translateTitle("Customer Service") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-content-two" :class="imgList.length == 0 ? '' : ''">
      <div class="content-two-title">
        {{ translateTitle("市场行情") }}
      </div>
      <div class="quotes-content">
        <el-table
          v-loading="tableLoading"
          :data="tableDataComputed"
          row-key="symbol_id"
          style="width: 100%"
          class="trade-table"
          @row-click="onClickRow"
        >
          <el-table-column
            :label="translateTitle('币对')"
            align="left"
            min-width="140"
            prop="from_symbol"
          >
            <template slot-scope="{ row }">
              <!-- @click="onSymbolClick(row)" -->
              <span class="coin-content" style="cursor: pointer">
                <img class="coin-img" :src="row.coin_url" alt="" />
                <span
                  class="left-symbol"
                  style="margin-left: 10px; font-size: 16px"
                  >{{ row.from_symbol }}</span
                >
                <span class="dark-color"> / {{ row.to_symbol }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('最新价')"
            align="left"
            prop="usd"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <div>
                <span class="DINPro left-row">
                  $ {{ formatNumberRgx(row.last) }}
                </span>
                <!-- <span class="dark-color DINPro"
                  >/ {{ systemCurrencyMaps[systemCurrency]
                  }}{{ formatNumberRgx(row.currency[systemCurrency]) }}
                </span> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('24H涨跌幅')"
            align="center"
            prop="info.price_change_percent"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <span
                class="left-row DINPro"
                :class="Number(row.price_change_percent) > 0 ? 'green' : 'red'"
                ><span
                  class="green"
                  v-show="Number(row.price_change_percent) > 0"
                  style="margin-right: -3px"
                >
                  + </span
                >{{ row.price_change_percent }} %</span
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="`24H` + ' ' + translateTitle('交易量')"
            align="center"
            prop="info.price_change_percent"
            min-width="140"
          >
            <template slot-scope="{ row }">
              <span class="left-row DINPro"
                >{{ formatNumberRgx(row.vol) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="translateTitle('行情')"
            align="center"
            prop="info.high"
            min-width="100"
          >
            <template slot-scope="{ row }">
              <img
                class="mrket-img"
                v-if="Number(row.price_change_percent) > 0"
                src="../../assets/img/markets/markt-up.png"
              />
              <img
                class="mrket-img"
                v-else
                src="../../assets/img/markets/markt-download.png"
              />
            </template>
          </el-table-column>

          <el-table-column
            :label="translateTitle('操作')"
            class="operate-column"
            align="right"
            min-width="90"
          >
            <template slot-scope="{ row }">
              <!-- @click="onSymbolClick(row)" -->
              <el-button @click="onSymbolClick(row)" class="operate">{{
                translateTitle("交易")
              }}</el-button>
            </template>
          </el-table-column>
          <no-data
            slot="empty"
            v-show="!tableLoading && tableDataComputed.length"
          ></no-data>
        </el-table>
        <div class="more-info">
          <div>
            <a :href="routeSplicing(language, 'markets')">
              <span @click="onLookMarket" class="more-title more">{{
                translateTitle("查看更多行情")
              }}</span>
              <img src="../../assets/img/ueex/home/arrow.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="home-content-introduce">
      <div class="content">
        <div class="introduce-title">
          {{
            translateTitle(
              "UEEx’s mission is to become your trusted and reliable partner for digital asset trading & investment"
            )
          }}
        </div>
        <div class="main">
          <div class="item">
            <div class="top">
              <img
                src="../../assets/img/ueex/home/introduce-icon1.svg"
                alt=""
              />
              <div class="top-title">
                {{ translateTitle("Quick Buy & Sell Crypto") }}
              </div>
              <div class="top-main">
                <div class="text">
                  {{
                    translateTitle(
                      "Buy BTC/ETH/USDT with Apple Pay, Visa and more."
                    )
                  }}
                </div>
                <div class="text">
                  {{
                    translateTitle(
                      "Support 10+ fiat currencies including USD, JPY, KRW."
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="btm">
              <!-- <div class="more-info">
                <div>
                  <a :href="routeSplicing(language, 'markets')">
                    <span @click="onLookMarket" class="more-title more">{{
                      translateTitle("View more")
                    }}</span>
                    <img src="../../assets/img/ueex/home/arrow.svg" alt="" />
                  </a>
                </div>
              </div> -->
            </div>
          </div>
          <div class="item">
            <div class="top">
              <img
                src="../../assets/img/ueex/home/introduce-icon2.svg"
                alt=""
              />
              <div class="top-title">
                {{ translateTitle("Competitive Fee Rate") }}
              </div>
              <div class="top-main">
                <div class="text">
                  {{ translateTitle("Preferential trading fee rates.") }}
                </div>
                <div class="text">
                  {{ translateTitle("Competitive VIP rights") }}
                </div>
                <div class="text">
                  {{ translateTitle("User-centric services") }}
                </div>
              </div>
            </div>
            <div class="btm">
              <!-- <div class="more-info">
                <div>
                  <a :href="routeSplicing(language, 'markets')">
                    <span @click="onLookMarket" class="more-title more">{{
                      translateTitle("View more")
                    }}</span>
                    <img src="../../assets/img/ueex/home/arrow.svg" alt="" />
                  </a>
                </div>
              </div> -->
            </div>
          </div>
          <div class="item">
            <div class="top">
              <img
                src="../../assets/img/ueex/home/introduce-icon3.svg"
                alt=""
              />
              <div class="top-title">
                {{ translateTitle("Security and Privacy Top of Mind") }}
              </div>
              <div class="top-main">
                <div class="text">
                  {{
                    translateTitle(
                      "UEEx prioritizes your privacy. We use unique addresses for your deposit and trades."
                    )
                  }}
                </div>
                <div class="text">
                  {{
                    translateTitle(
                      "Our sophisticated security measures and SAFT fund protect your digital assets from all risks."
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="btm">
              <!-- <div class="more-info">
                <div>
                  <a :href="routeSplicing(language, 'markets')">
                    <span @click="onLookMarket" class="more-title more">{{
                      translateTitle("View more")
                    }}</span>
                    <img src="../../assets/img/ueex/home/arrow.svg" alt="" />
                  </a>
                </div>
              </div> -->
            </div>
          </div>
          <div class="item">
            <div class="top">
              <img
                src="../../assets/img/ueex/home/introduce-icon4.svg"
                alt=""
              />
              <div class="top-title">
                {{ translateTitle("Superior liquidity and stability") }}
              </div>
              <div class="top-main">
                <div class="text">
                  {{
                    translateTitle(
                      "We have confident in our product and liquidity."
                    )
                  }}
                </div>
                <div class="text">
                  {{
                    translateTitle(
                      "UEEx promises 100% repay for clients’ fund lost due to security breach, technology glitch or insufficient liquidity."
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="btm">
              <!-- <div class="more-info">
                <div>
                  <a :href="routeSplicing(language, 'markets')">
                    <span @click="onLookMarket" class="more-title more">{{
                      translateTitle("View more")
                    }}</span>
                    <img src="../../assets/img/ueex/home/arrow.svg" alt="" />
                  </a>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-content-download">
      <div class="content">
        <div class="left">
          <img src="../../assets/img/ueex/home/download-banner.png" alt="" />
        </div>
        <div class="right">
          <div class="download-title">
            <div>
              {{ translateTitle("Download UEEx APP") }}
            </div>
            <div class="t2">
              {{ translateTitle("Trade Anytime Anywhere!") }}
            </div>
          </div>
          <div class="img-box">
            <a :href="appstore_url" target="_blank" rel="noopener noreferrer">
              <img src="../../assets/img/ueex/home/app-store.svg" alt="" />
            </a>
            <div
              class="download-item qr-code"
              @mouseenter="onShowDownLoad"
              @mouseleave="onHiddenDownLoad"
            >
              <img src="../../assets/img/ueex/home/android.svg" alt="" />
              <div v-show="showDownLoadImg" class="download-code">
                <img class="qr_code_canvas" :src="appDownLoadImg" alt="" />
                <div class="scren-code">{{ translateTitle("扫码下载") }}</div>
              </div>
            </div>
            <a
              href="https://play.google.com/store/apps/details?id=com.ueasy8.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="../../assets/img/ueex/home/google-play.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="home-content-partners">
      <div class="hcp-title">
        <p class="ht-main">
          <!-- Our partners -->
          {{ translateTitle("我们的合作伙伴") }}
        </p>
      </div>
      <div class="hcp-content">
        <div class="hc-imgs">
          <img class="img-c" src="../../assets/img/ueex/Partner_CMC.svg" />
        </div>
        <div class="hc-imgs V2">
          <img
            class="img-c"
            src="../../assets/img/ueex/Partner_Feixiaohao.svg"
          />
        </div>
        <div class="hc-imgs V2">
          <img class="img-c" src="../../assets/img/ueex/Partner_Slowmist.svg" />
        </div>
        <div class="hc-imgs V2">
          <img class="img-c" src="../../assets/img/ueex/Partner_Sumsub.svg" />
        </div>
        <div class="hc-imgs V2">
          <img class="img-c" src="../../assets/img/ueex/Partner_UPay.svg" />
        </div>
      </div>
    </div>
    <div class="home-content-coin">
      <div class="content">
        <div class="oe-coin">
          <div class="top">
            <div class="left">
              <div class="coin-top-title">
                {{ translateTitle("UECoin") }}
              </div>
              <div class="coin-top-text">
                {{
                  translateTitle(
                    "UE Coin(UE) is the native cryptocurrency of UEEx, serving as the exchange token of the platform. "
                  )
                }}
              </div>
            </div>
            <div class="right">
              <img src="../../assets/img/ueex/home/coin-right.svg" alt="" />
            </div>
          </div>
          <div class="btm">
            <div class="item">
              <div class="btm-item-title">
                {{ 0.079755 }}
              </div>
              <div class="btm-item-text">
                {{ translateTitle("Latest Price (USD)") }}
              </div>
            </div>
            <div class="item">
              <div class="btm-item-title">10.00B</div>
              <div class="btm-item-text">
                {{ translateTitle("Total Supply (UE)") }}
              </div>
            </div>
            <div class="item">
              <div class="btm-item-title">2.78B</div>
              <div class="btm-item-text">
                {{ translateTitle("Circulating Supply (UE)") }}
              </div>
            </div>
            <div class="item">
              <div class="btm-item-title">71.79%</div>
              <div class="btm-item-text">
                {{ translateTitle("Burnt Ratio") }}
              </div>
            </div>
          </div>
        </div>
        <div class="faq">
          <div class="title">
            {{ translateTitle("FAQ") }}
          </div>
          <el-collapse class="fap-collapse" v-model="activeName" accordion>
            <el-collapse-item name="1">
              <div>
                {{ translateTitle("加密货币交易平台是一个数字市场，用户可以在此买卖比特币、以太坊、泰达币等主流加密货币。作为全球领先的加密货币交易平台之一，UEEx优易凭借卓越的交易量和用户体验，致力于成为数字资产交易者的首选平台。") }}
              </div>
              <template #title>
                <div class="custom-title">
                  <span>
                    {{ translateTitle("1. 什么是加密货币交易平台？") }}
                  </span>
                  <span class="custom-icon">{{
                    activeName === "1" ? "-" : "+"
                  }}</span>
                </div>
              </template>
            </el-collapse-item>
            <el-collapse-item name="2">
              <div>
                {{ translateTitle("数字货币是一种基于区块链技术的去中心化资产，具有高度透明、无边界交易和不可篡改等特性。目前，市值最大的数字货币依次是比特币、以太坊和泰达币，用户均可在UEEx优易平台上进行交易。") }}
              </div>
              <template #title>
                <div class="custom-title">
                  <span>{{translateTitle("2. 什么是数字货币？")}}</span>
                  <span class="custom-icon">{{
                    activeName === "2" ? "-" : "+"
                  }}</span>
                </div>
              </template>
            </el-collapse-item>
            <el-collapse-item name="3">
              <div>
                {{ translateTitle("您可以到UEEx优易快捷买币，使用信用卡、借记卡、Apple Pay或Google Pay进行买币。") }}
              </div>
              <template #title>
                <div class="custom-title">
                  <span>{{translateTitle("3. 如何在UEEx优易购买比特币和其他加密货币？")}}</span>
                  <span class="custom-icon">{{
                    activeName === "3" ? "-" : "+"
                  }}</span>
                </div>
              </template>
            </el-collapse-item>
            <el-collapse-item name="4">
              <div>
                <div>{{ translateTitle("· 现货交易") }}</div>
                <div>{{ translateTitle("· 合约交易") }}</div>
                <div>{{ translateTitle("· 超级杠杆交易") }}</div>
                <div>{{ translateTitle("· 抵押借贷产品") }}</div>
                <div>{{ translateTitle("· 期权交易") }}</div>
                <div>{{ translateTitle("· 跟单交易") }}</div>
                <div>{{ translateTitle("· 策略交易") }}</div>
                <div>{{ translateTitle("· 股票交易") }}</div>
              </div>
              <template #title>
                <div class="custom-title">
                  <span>{{translateTitle("4. UEEx优易有哪些产品选项？")}}</span>
                  <span class="custom-icon">{{
                    activeName === "4" ? "-" : "+"
                  }}</span>
                </div>
              </template>
            </el-collapse-item>
            <el-collapse-item name="5">
              <div>
                {{ translateTitle("您可以通过现货交易、合约交易和超级杠杆交易等UEEx优易产品交易数百种加密货币。开始交易前，您只需注册账户、完成身份验证（非强制），并购买或存入加密货币后即可开始交易。") }}
              </div>
              <template #title>
                <div class="custom-title">
                  <span>{{translateTitle("5. 如何在UEEx优易交易加密货币？")}}</span>
                  <span class="custom-icon">{{
                    activeName === "5" ? "-" : "+"
                  }}</span>
                </div>
              </template>
            </el-collapse-item>
            <el-collapse-item name="6">
              <div>
                {{ translateTitle("投资加密货币涉及几个步骤和注意事项。您应了解加密货币的基础知识、设定投资目标，并选择像UEEx优易这样信誉良好的交易所，以确保投资安全。请记住，投资总是存在风险，因此在做出决策之前，进行充分的研究至关重要。") }}
              </div>
              <template #title>
                <div class="custom-title">
                  <span>{{translateTitle("6. 如何开始加密货币投资？")}}</span>
                  <span class="custom-icon">{{
                    activeName === "6" ? "-" : "+"
                  }}</span>
                </div>
              </template>
            </el-collapse-item>
            <el-collapse-item name="7">
              <div>
                {{ translateTitle("您可以到UEEx优易") }}
                <a v-if="language.includes('zh')" href="https://support.ueex.com/hc/zh-cn/articles/4541636441103-%E8%B4%B9%E7%8E%87%E6%A0%87%E5%87%86">{{translateTitle("费率标准")}}</a>
                <a v-else href="https://support.ueex.com/hc/en-us/articles/4541636441103-Rate-Standard">{{translateTitle("费率标准")}}</a>
                {{ translateTitle("进行查看。") }}
              </div>
              <template #title>
                <div class="custom-title">
                  <span>{{translateTitle("7. UEEx优易费率标准？")}}</span>
                  <span class="custom-icon">{{
                    activeName === "7" ? "-" : "+"
                  }}</span>
                </div>
              </template>
            </el-collapse-item>
            <el-collapse-item name="8">
              <div>
                {{ translateTitle("您可以通过点击App或网页端的“联系客服”图标进行在线咨询，也可以发送邮件至以下地址，相关客服将在24小时内回复您：") }}
                <a href="mailto:customer-service@ueex.com" target="_blank">customer-service@ueex.com</a>
              </div>
              <template #title>
                <div class="custom-title">
                  <span>{{translateTitle("8. 如何联系UEEx优易官方客服？")}}</span>
                  <span class="custom-icon">{{
                    activeName === "8" ? "-" : "+"
                  }}</span>
                </div>
              </template>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <div v-show="!userInfoShow" class="home-content-six">
      <div class="six-content">
        <p class="six-content-title">
          {{ translateTitle("Start Your Trading Journey Now!") }}
        </p>
        <p class="six-content-title V2">
          {{
            translateTitle(
              "Create your account in less than 10 seconds, start 20X margin trading, 100X contract trading NOW!"
            )
          }}
        </p>

        <div class="regsiter-content">
          <input
            v-model="resiterVal"
            type="text"
            :placeholder="translateTitle('输入邮箱/手机号码')"
          />
          <span @click="onResiter(2)" class="goRegsiter theme-btn">{{
            translateTitle("注册")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "@/components/common/commonMixin.vue";
import Socket from "@/assets/js/socket.js";
import { floorNumber, ceilNumber } from "@/assets/js/digital";
import { apiGetStaticTableInfo } from "@/model/settings";
import NoData from "@/components/common/NoData";
import Cookies from "js-cookie";
import { apiGetBannerList, apiChannelPartnerList } from "@/model/home/index";
import { mapActions, mapGetters } from "vuex";
import { apiWsUrl, apiGetSpotList } from "@/model/exchange/index";
import { systemCurrencyMaps } from "@/assets/js/keymap.js";
import axios from "axios";
var vm = null;
import { VUE_APP_ID, VUE_APP_APP } from "@/assets/js/stationSetting";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],
  components: {
    NoData,
  },
  data() {
    return {
      tt: {}, //WS延时触发器
      lockReconnect: false, //避免重复连接
      wsContentTime: 0,
      swiperOptionTop: {
        slidesPerView: 1,
        spaceBetween: 15,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      detailsUrl: "",
      systemCurrencyMaps,
      token: "",
      wsUrl: "",
      appDownLoadImg: "",
      activeName: "1",
      activeLi: "2",
      // realIndex:0,
      sideLen: 4,
      isResiter: false,
      resiterVal: "",
      resiterValue: "",
      isMobile: false,
      isEmail: false,
      showDownLoadImg: false,
      android_url: "",
      appstore_url: "",
      doanGoogleUrl: "https://www.topcreditbank.com/app_download/",
      doanIosUrl: "https://apps.apple.com/app/id1525081932",
      tipUrl: "",

      firstArticles: {},
      secondArticles: {},
      swiperOption: {
        initialSlide: 0,

        // 箭头配置
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
        on: {
          click: function () {
            // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
            // console.log(this); // -> Swiper
            // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
            const realIndex = this.realIndex;
            vm.handleClickSlide(realIndex);
          },
        },

        // slidesPerView: 1,
        spaceBetween: 24,
        slidesPerView: 4,
        loopedSlides: 8,
        slidesPerGroup: 1,
        // slidesPerView: 'auto',
        // // spaceBetween: 10,
        // // slidesPerView: "auto",
        // loopedSlides: 1,
        // slidesPerGroup: 'auto',
        loopFillGroupWithBlank: true,
        infinite: 1, // 无限滚动前后遍历数
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 4000,
        },
        autoplayDisableOnInteraction: false,
        // //循环
        loop: true,
        // // //每张播放时长3秒，自动播放
        // autoplay: 2000,
        // // //滑动速度
        // speed: 1000,
      },
      oldImgList: [
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-1.png"),
            img_url_en: require("../../assets/img/home/banner-1.png"),
            img_url_ja: require("../../assets/img/home/banner-1.png"),
            img_url_ko: require("../../assets/img/home/banner-1.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-1.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-1.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-2.png"),
            img_url_en: require("../../assets/img/home/banner-2.png"),
            img_url_ja: require("../../assets/img/home/banner-2.png"),
            img_url_ko: require("../../assets/img/home/banner-2.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-2.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-2.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-3.png"),
            img_url_en: require("../../assets/img/home/banner-3.png"),
            img_url_ja: require("../../assets/img/home/banner-3.png"),
            img_url_ko: require("../../assets/img/home/banner-3.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-3.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-3.png"),
          },
          param: { url: "" },
        },
        {
          language: {
            img_url_ar: require("../../assets/img/home/banner-4.png"),
            img_url_en: require("../../assets/img/home/banner-4.png"),
            img_url_ja: require("../../assets/img/home/banner-4.png"),
            img_url_ko: require("../../assets/img/home/banner-4.png"),
            img_url_zh_CN: require("../../assets/img/home/banner-4.png"),
            img_url_zh_HK: require("../../assets/img/home/banner-4.png"),
          },
          param: { url: "" },
        },
      ],
      imgList: [],
      articlesList: [],
      tableDataComputed: [],
      exchangeCoinMap: {},
      coinInfoList: {},
      tableLoading: true,
      showDownLoadImgV2: false,
      tableData: [],
      tempMarkList: [],
      activeSymbol: "1",
      collectSymbol: [22],
      ws: null,
      tgUrl: "",
      rateUrl: "",
      timer: null,
      currentVideo: "https://static.aws-s1.com/v/MaskExIFX2023.m4v",
      voucherIntervalV2: null,
      partnerList: [],
      activeNames: "1",
    };
  },
  mounted() {
    // this.voucherIntervalV2 = setInterval(() => {
    //   document.title = "UEEx: The Best Crypto and Stock Exchange Platform";
    // }, 800);
  },
  async created() {
    this.getPartnerList();
    await this.getExchangeCoinMap();
    let qrcode_download = JSON.parse(sessionStorage.getItem("qrcode_download"));
    let about = JSON.parse(sessionStorage.getItem("about"));
    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    this.appDownLoadImg = qrcode_download.url;

    if (zendesk_notice.language) {
    } else {
      this.tipUrl = zendesk_notice.notice_url;
    }

    if(this.language == "zh_CN"){
      this.tipUrl = "https://support.ueex.com/hc/zh-cn/categories/4761686080783-%E5%85%AC%E5%91%8A%E4%B8%AD%E5%BF%83"
      this.detailsUrl = "https://support.ueex.com/api/v2/help_center/zh-cn/categories/4761686080783/articles";
    }else{
      this.tipUrl = "https://support.ueex.com/hc/en-us/categories/4761686080783-Announcement"
      this.detailsUrl = "https://support.ueex.com/api/v2/help_center/en-us/categories/4761686080783/articles"
    }

    let othersList = JSON.parse(sessionStorage.getItem("others"));
    this.rateUrl = othersList[0].url;
    let qrcode = JSON.parse(sessionStorage.getItem("qrcode"));
    this.tgUrl = qrcode.ori_url;
    this.getBannerData();
    await this.getStaticTableInfo(1);
    this.getDeatilsData();
    let tempCookies = Cookies.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      await this.initURL();
      this.initWS();
    } else {
      this.token = Cookies.get("topcredit_exchange_t");
      await this.initURL();
      this.initWS();
    }
    vm = this;
    // await this.getItemList();
    this.getTradePairList();
    this.voucherInterval = setInterval(() => {
      // this.getBannerData();
      this.getDeatilsData();
    }, 5000 * 60 * 6);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
    clearInterval(this.voucherIntervalV2);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
    clearInterval(this.voucherIntervalV2);
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency",
    }),

    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
  },

  methods: {
    routeSplicing,
    floorNumber,
    jumpToBolg() {
      window.open(``, "_blank");
    },
    async getPartnerList() {
      const { status, data } = await apiChannelPartnerList();
      if (status == 200) {
        this.partnerList = data;
      }
    },
    jumpToUrlV2(item) {
      if (item.language) {
        window.open(item.language[`url_${this.language}`]);
      } else {
        window.open(item.param.url);
      }
    },
    onVideoClick(type) {
      this.activeLi = type;
      if (type == 1) {
        this.currentVideo = "https://static.aws-s1.com/v/AppBenefit.mp4";
      } else if (type == 2) {
        this.currentVideo = "https://static.aws-s1.com/v/MaskExIFX2023.m4v";
      } else {
        this.currentVideo =
          "https://static.aws-s1.com/v/CopyTrading_1920x1080.mp4";
      }
    },
    // onRegisterClick() {
    //   this.$router.push(`/register/${this.language}`);
    // },
    async getExchangeCoinMap() {
      const { status, data } = await apiGetStaticTableInfo(55);
      if (status == 200) {
        let tempObj = {};
        for (const key in data) {
          if (data[key].symbol_type == 1 && data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        this.exchangeCoinMap = JSON.parse(JSON.stringify(tempObj));
      }
    },

    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
        this.android_url = data.android_url;
        this.appstore_url = data.appstore_url;
      }
    },
    handleClick() {},
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     sessionStorage.setItem("itemList", JSON.stringify(data));
    //   }
    // },
    onResiter(type) {
      var name = "";
      var inputVal = "";
      if (type == 1) {
        inputVal = this.resiterValue;
      } else {
        inputVal = this.resiterVal;
      }
      var emailReg = new RegExp(
        "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
      ); //正则表达式
      var numReg = /^\d{9,}$/;
      if (inputVal) {
        if (emailReg.test(inputVal)) {
          name = "email";
        } else if (numReg.test(inputVal)) {
          name = "phone";
        } else {
          name = "account";
        }
      } else {
        name = "";
        inputVal = "";
      }
      this.$router.push({
        name: "Register",
        params: {
          name: name,
          val: inputVal,
        },
      });
    },
    handleClickSlide(index) {
      console.log("當前點擊索引：", index);
    },
    onClickMore() {
      window.open(this.tipUrl);
    },
    onLookMarket() {
      //   this.$router.push(this.routeSplicing(this.language, "markets"));
    },
    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },
    onShowDownLoadV2() {
      this.showDownLoadImgV2 = true;
    },
    onHiddenDownLoadV2() {
      this.showDownLoadImgV2 = false;
    },

    async getBannerData() {
      const req = {
        type: 1,
        title: "main",
      };
      const { status, data } = await apiGetBannerList(req);
      if (status == 200) {
        let len = data.length;
        // let len = 1;
        let arr = [...data];
        // let arr = [data[0]];
        if (len < 4) {
          for (let i = 0; i < 4 - len; i++) {
            arr.push({
              showtype: 1,
              src: require("../../assets/img/mask/banner-maskex" +
                (i + 1) +
                ".webp"),
            });
          }
          this.imgList = arr;
        } else {
          this.imgList = data;
        }
        // this.sideLen = len >= 4 ? 4 : len;

        // this.imgList = arr;
      }
    },
    async getStaticTableInfo(sv_id) {
      const { status, data } = await apiGetStaticTableInfo(sv_id);
      if (status == 200) {
        this.coinInfoList = JSON.parse(JSON.stringify(data));
      }
    },

    async getDeatilsData() {
      var that = this;
      axios
        .get(this.detailsUrl)
        .then(function (response) {
          that.articlesList = response.data.articles;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getTradePairList() {
      apiGetSpotList().then((res) => {
        if (res.status == 200) {
          let tempArr = res.data;
          tempArr.forEach((ele) => {
            ele.lastV2 = Number(ele.last);
            ele.vol = floorNumber(ele.vol, 2);
            ele.currency.usdV2 = Number(ele.currency.usd);
            ele.price_change_percentV2 = Number(ele.price_change_percent);
            for (const key in this.exchangeCoinMap) {
              if (ele.symbol_id == key) {
                ele["from_symbol"] = this.exchangeCoinMap[key].name
                  .split("_")[0]
                  .toUpperCase();
                ele["to_symbol"] = this.exchangeCoinMap[key].name
                  .split("_")[1]
                  .toUpperCase();
                ele["info"] = this.exchangeCoinMap[key];
                ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
                this.tempMarkList.push(ele);
              }
            }
            for (const key in this.coinInfoList) {
              if (ele.from_symbol) {
                if (
                  this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
                ) {
                  ele.coin_url = this.coinInfoList[key].coin_url;
                }
              }
            }
          });
          this.tableLoading = false;
          // this.tableDataComputed = this.tempMarkList;
          let tempList = this.tempMarkList.filter((ele) => {
            return ele.from_symbol != undefined && ele.web_recommend == 1;
          });
          this.tableDataComputed = tempList.sort(
            this.compareOrderBy("order_by")
          );
        }
      });
    },
    initWS() {
      let me = this;
      this.tableLoading = true;
      let wsUrl = this.wsUrl;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "marketM",
            app_id: VUE_APP_ID,
          },
        },
        callback: this.onResponse,
        onConnect,
      };
      window.USDT_WS = this.ws = new Socket(option).getInstance();
      //   setTimeout(() => {}, 1200);
      me.global.setWs(this.ws);
      function onConnect() {
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWS();
          me.lockReconnect = false;
        }, 3000);
      }
      // this.getSubData();
    },
    onResponse(data) {
      this.tableLoading = false;
      if (data.action == "Pushdata.marketM") {
        this.tableData = data.data;

        if (this.tableData == null || this.tableData.length == 0) {
          return;
        }
        this.tableData.forEach((ele) => {
          ele.vol = floorNumber(ele.vol, 2);
          for (const key in this.exchangeCoinMap) {
            if (key == ele.symbol_id) {
              ele["from_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[0]
                .toUpperCase();
              ele["to_symbol"] = this.exchangeCoinMap[key].name
                .split("_")[1]
                .toUpperCase();
              ele["info"] = this.exchangeCoinMap[key];
              ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
            }
          }
          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (
                this.coinInfoList[key].name == ele.from_symbol.toLowerCase()
              ) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
        });
        let tempList = this.tableData.filter((ele) => {
          return ele.from_symbol != undefined && ele.web_recommend == 1;
        });

        this.tableDataComputed = tempList.sort(this.compareOrderBy("order_by"));
      }
    },
    compareOrderBy(property) {
      return function (a, b) {
        if (
          a &&
          b &&
          a["info"] &&
          b["info"] &&
          a["info"][property] &&
          b["info"][property]
        ) {
          let value1 = a["info"][property];
          let value2 = b["info"][property];
          if (value1 - value2 != 0) {
            return value1 - value2;
          } else {
            let temp1 = a["from_symbol"];
            let temp2 = b["from_symbol"];
            if (temp1 < temp2) {
              // 按某种排序标准进行比较, a 小于 b
              return -1;
            }
            if (temp1 > temp2) {
              return 1;
            }
            return 0;
          }
        } else {
          return 0;
        }
      };
    },
    onTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
    },
    onSymbolClick(row) {
      let tempUrl = this.routeSplicing(
        this.language,
        `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      );
      window.open(tempUrl);
    },

    goTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
    },
    onClickRow(row, column, event) {
      // let tempUrl = this.routeSplicing(
      //   this.language,
      //   `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      // );
      // window.open(tempUrl);
    },
  },
};
</script>
<style lang="scss">
.ar .ueex-home-page .home-content-five .five-items-content .five-content-items {
  padding: 35px 50px 0 0;
}
.en .ueex-home-page .home-content-one .home-sign {
  top: 54px;
  .top {
    // font-family: DIN;
    font-family: "Plus Jakarta Sans";
    font-weight: bold;
  }
  .middle {
    // font-family: DIN;
    font-size: 43px;
    font-weight: bold;
  }
}
@keyframes a {
  0% {
    transform: translate(0);
  }

  to {
    transform: translateY(-12px);
  }
}
@keyframes b {
  0% {
    transform: translateY(0);
  }
  // 50% {
  //   transform: translateY(100%);
  // }
  100% {
    transform: translateY(100%);
  }
}
@keyframes bounce-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
.ar
  .ueex-home-page
  .home-content-five
  .five-content
  .details
  .left
  .text-item
  .pointer {
  margin-right: 0;
  margin-left: 20px;
}
.ar .ueex-home-page .home-content-two {
  direction: ltr !important;
}
.zh_CN {
  //   .ueex-home-page .home-content-one .home-sign {
  //     display: flex;
  //     justify-content: center;
  //     top: 90px;
  //   }
}
.zh_CN {
  //   .ueex-home-page .home-content-one .home-sign {
  //     display: flex;
  //     justify-content: center;
  //     top: 90px;
  //   }
}
.zh_HK {
  //   .ueex-home-page .home-content-one .home-sign {
  //     display: flex;
  //     justify-content: center;
  //     top: 90px;
  //   }
}
.ar .ueex-home-page .home-content-four .left-content .tips-details li i {
  margin-right: 0;
  margin-left: 6px;
}
.ar .ueex-home-page .regsiter-content input {
  margin: 0 0 0 10px;
  padding: 12px 17px 12px 0;
}
.ar {
  .ueex-home-page
    .home-content-five
    .five-items-content
    .five-content-items
    .text-title {
    text-align: right;
  }
  .ueex-home-page
    .home-content-five
    .five-items-content
    .five-content-items
    .text-item
    .pointer {
    margin-right: 0;
    margin-left: 20px;
  }
  .ueex-home-page .home-content-five .five-content .details .right {
    direction: ltr !important;
  }
  .ueex-home-page .el-table tbody .cell .coin-content {
    // float: right;
    margin-right: 80px;
    .coin-img {
      margin-left: 10px;
    }
  }

  .ueex-home-page .home-content-four .left-content .tips-details li i {
    margin-left: 6px;
    margin-right: 0;
  }
  .ueex-home-page .home-content-four .left-content .download-content {
    direction: ltr;
  }
  .ueex-home-page .home-content-four .left-content .download-code {
    height: 160px;
    .qr_code_canvas {
      margin-right: 0;
    }
  }
}
.ueex-home-page {
  margin-top: -2px;
  // height: 2977px;
  display: block;
  background: #f4f4f5;
  // user-select: none;
  padding: 0;
  flex: 1;
  [v-cloak] {
    display: none;
  }
  .fiv-svg {
    position: relative;
    i {
      z-index: 900;
      position: absolute;
      font-size: 60px;
      top: -2px;
      left: 110px;
    }
  }
  .hor-line {
    width: 74px;
    height: 6px;
    border-radius: 3px;
    background-image: linear-gradient(89deg, #00cf88 0%, #0084d6 100%);
    margin: 0 auto;
    display: block;
  }
  // font-family: PingFangSC, BinancePlex, Arial, sans-serif !important;
  .is-resiger {
    height: 340px;
  }
  .no-resiger {
    height: 340px;
  }

  .swiper-img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  .home-content-one {
    background: url("../../assets/img/ueex/home/home-bg.svg") lightgray 50% /
      cover no-repeat;

    .content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 400px;
    }
    .left {
      width: 50%;
      text-align: left;
    }
    .right {
      img {
        width: 340px;
        height: 340px;
      }
    }
    // img {
    //   height: 400px;
    // }
    .coin-ico {
      position: absolute;
      // animation: b 1.5s ease-in-out 2s infinite alternate;

      &.usdt {
        animation: bounce-down 1s ease-in-out 2s infinite alternate;
        bottom: 10%;
        right: 20%;
      }
      &.btc {
        animation: bounce-down 1.3s ease-in-out 2s infinite alternate;
        top: 5%;
        right: 24%;
      }
      &.eth {
        // animation: b 0.5s ease-in-out 0.5s infinite alternate;
        animation: bounce-down 1.6s ease-in-out 0.5s infinite alternate;
        bottom: 20%;
        left: 15%;
      }
    }
    .home-sign {
      font-size: 40px;
      line-height: 150%;
      font-weight: normal;
      text-align: left;
      color: #15192a;
      width: 100%;
      .top {
        font-size: 40px;
        font-weight: 600;
      }
      .middle {
        font-weight: bolder;
      }
      &.V2 {
        gap: 21px;
        display: flex;
        margin-top: 40px;
        font-size: 24px;
        line-height: 100%;
        font-weight: 400;
        top: 170px;
        color: #18181b;
      }
    }
    .register-btn {
      cursor: pointer;
      height: 54px;
      padding: 13px 150px;
      border-radius: 50px;
      background-image: linear-gradient(87deg, #00cf88 0%, #0084d6 100%);
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      position: absolute;
      bottom: 60px;
      left: calc(50% - 210px);
    }
  }
  .home-content-banner {
    background: #fff;

    .content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      min-height: 460px;
      .left {
        .swiper-wrapper {
          width: 546.449px;
          height: 300px;
          border-radius: 20px;
        }
        .swiper-button-prev:after,
        .swiper-button-next:after {
          font-family: swiper-icons;
          font-size: 10px;
          font-weight: 900;
          text-transform: none !important;
          letter-spacing: 0;
          text-transform: none;
          font-variant: initial;
          line-height: 1;
        }
        .swiper-button-prev,
        .swiper-button-next {
          &:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }
          color: #fff;
          background-color: rgba(255, 255, 255, 0.1);

          border-radius: 50%;
          width: 30px;
          height: 30px;
        }
        .swiper-pagination {
          span {
            background-color: #FFF;
            opacity: 1;
          }
          .swiper-pagination-bullet-active {
            opacity: 1;
            border-color: #FFF;
            width: 30px;
            border-radius: 5px;
            background-color: #FFF;
          }
        }
      }
      .right {
        height: 300px;
        flex: 1;
        .banner-title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .title {
            color: #18181b;
            font-feature-settings: "liga" off, "clig" off;
            font-family: "Plus Jakarta Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%; /* 100% */
            .more {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .banner-text {
          .item {
            margin-top: 40px;
            color: #18181b;
            font-feature-settings: "liga" off, "clig" off;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 100% */
            a {
              color: #18181b;
            }
          }
        }
      }
    }
  }
  .home-content-growing {
    background: #F6F7F8;

    .content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 630px;
      .growing-title {
        color: var(--Neutral-900, #18181b);
        text-align: center;
        font-feature-settings: "liga" off, "clig" off;
        font-family: "Plus Jakarta Sans";
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 100% */
      }
      .growing-mid {
        margin-top: 80px;
        display: flex;
        justify-content: space-around;
        width: 100%;
        .item {
          .mid-title {
            color: var(--Brand-500, #f28c34);
            text-align: center;
            font-feature-settings: "liga" off, "clig" off;
            font-family: "Plus Jakarta Sans";
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px; /* 100% */
          }
          .mid-text {
            margin-top: 20px;
            color: #18181b;
            text-align: center;
            font-feature-settings: "liga" off, "clig" off;
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px; /* 100% */
          }
        }
      }
      .growing-btm {
        margin-top: 66px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 20px;
        background: #fff;
        padding: 80px 54px;
        .item {
          .btm-title {
            color: var(--Brand-500, #f28c34);
            text-align: center;
            font-feature-settings: "liga" off, "clig" off;
            font-family: "Plus Jakarta Sans";
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px; /* 100% */
          }
          .btm-text {
            margin-top: 20px;
            color: #18181b;
            text-align: center;
            font-feature-settings: "liga" off, "clig" off;
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px; /* 100% */
          }
        }
      }
    }
  }
  .home-content-introduce {
    background: #F6F7F8;

    .content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 720px;
      .introduce-title {
        width: 874px;
        color: #18181b;
        text-align: center;
        font-feature-settings: "liga" off, "clig" off;
        font-family: "Plus Jakarta Sans";
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px; /* 142.857% */
      }
      .main {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
        gap: 40px;

        .item {
          width: 253px;
          height: 402px;
          flex-shrink: 0;
          border-radius: 8px;
          background: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 20px;
          .top {
            .top-title {
              margin-top: 20px;
              color: #18181b;
              font-feature-settings: "liga" off, "clig" off;
              font-family: "Plus Jakarta Sans";
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 16px; /* 100% */
            }
            .top-main {
              .text {
                display: flex;
                gap: 4px;
                margin-top: 20px;
                color: #18181b;
                font-feature-settings: "liga" off, "clig" off;
                font-family: "Plus Jakarta Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
              }
              .text::before {
                content: "·";
                display: block;
                color: #18181b;
                font-feature-settings: "liga" off, "clig" off;
                font-family: "Plus Jakarta Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 800;
                line-height: 20px; /* 100% */
              }
            }
          }
          .btm {
            .more-info {
              text-align: center;
              font-size: 16px;
              color: #7f8490;
              > div {
                a {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                }
              }
              .more-title {
                width: auto;
                vertical-align: middle;
                cursor: pointer;
                color: #f28c34;
                font-feature-settings: "liga" off, "clig" off;
                font-family: "Plus Jakarta Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px; /* 100% */
              }
              .line {
                width: 1px;
                height: 18px;
                vertical-align: middle;
                display: inline-block;
                margin: 2px 18px;
                background-color: #c2c7d0;
              }
              .more {
                cursor: pointer;
                vertical-align: middle;
                &:hover {
                  color: #3a7dff;
                  span {
                    color: #3a7dff;
                  }
                }
              }
              span {
                color: #9aa1b1;
              }
              .icongengduo {
                color: #7f8490;
                font-size: 14px;
                vertical-align: sub;
              }
            }
          }
        }
      }
    }
  }
  .home-content-download {
    background: #fff;
    .content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      gap: 120px;
      justify-content: space-between;
      align-items: center;
      min-height: 780px;
      .left {
        img {
          width: 248px;
          height: 500px;
          flex-shrink: 0;
        }
      }
      .right {
        flex: 1;
        .download-title {
          color: #111a34;
          font-feature-settings: "liga" off, "clig" off;
          font-family: "Plus Jakarta Sans";
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          .t2 {
            margin-top: 20px;
            color: #f28c34;
          }
        }
        .img-box {
          margin-top: 120px;
          display: flex;
          gap: 20px;
          img {
            width: 160px;
            height: 48px;
            flex-shrink: 0;
          }
          .download-item {
            position: relative;
            .download-code {
              position: absolute;
              .qr_code_canvas {
                width: 160px;
                height: 160px;
              }
              .scren-code {
                width: 100%;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  .home-content-two {
    min-height: 360px;
    background: #fff;
    padding-top: 80px;
    padding-bottom: 20px;
    &.V2 {
      min-height: 588px;
    }

    .tips-massage-content {
      padding: 20px 0px;
      font-size: 14px;
      color: #080c34;
      display: flex;
      align-items: center;
      justify-content: center;
      .left {
        // min-width: 265px;
        // width: auto;
        i {
          font-size: 16px;
          color: #080c34;
        }
      }
      .left-driver {
        width: 1px;
        height: 18px;
        margin: 2px 15px 0;
        background-color: #c2c7d0;
      }

      .bottom {
        // min-width: 200px;
        // margin-left: 15px;
        text-align: left;
      }
      .article-title {
        color: #080c34 !important;
        min-width: 250px;
      }
      .right-driver {
        width: 1px;
        height: 18px;
        margin: 2px 20px 0 15px;
        background-color: #c2c7d0;
      }
      .right {
        cursor: pointer !important;
        i {
          font-size: 14px;
          color: #9aa1b1;
          margin-top: 2px;
          &:hover {
            color: #3a7dff;
          }
        }
        &:hover {
          color: #3a7dff;
          i {
            color: #3a7dff;
          }
        }
      }
    }
    .content-two-title {
      width: 1132px;
      margin: 0 auto;
      color: var(--Neutral-900, #18181b);
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "Plus Jakarta Sans";
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 100% */
      margin-bottom: 80px;
    }
    .quotes-content {
      width: 1132px;
      // height: 572px;
      margin: 0 auto;
      // border-radius: 6px;
      // box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
      .left-symbol {
        color: #354052;
        font-size: 18px;
        font-weight: 500;
      }
      .left-row {
        color: #18181B;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 100% */
        font-size: 18px;
      }
      .el-table th,
      .el-table tr {
        background: #fff;
      }
      .el-table td,
      .el-table th.is-leaf {
        border-bottom: none;
      }
      .el-table th.is-right .cell {
        padding-right: 40px;
      }
      .el-table {
        tr {
          cursor: pointer;
        }
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #f1f5f6;
      }
      .more-info {
        display: flex;
        align-items: center;
        height: 72px;
        justify-content: center;
        text-align: center;
        font-size: 16px;
        color: #7f8490;
        > div {
          a {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
        .more-title {
          width: auto;
          vertical-align: middle;
          cursor: pointer;
          color: #f28c34;
          font-feature-settings: "liga" off, "clig" off;
          font-family: "Plus Jakarta Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px; /* 100% */
        }
        .line {
          width: 1px;
          height: 18px;
          vertical-align: middle;
          display: inline-block;
          margin: 2px 18px;
          background-color: #c2c7d0;
        }
        .more {
          cursor: pointer;
          vertical-align: middle;
          &:hover {
            color: #3a7dff;
            span {
              color: #3a7dff;
            }
          }
        }
        span {
          color: #9aa1b1;
        }
        .icongengduo {
          color: #7f8490;
          font-size: 14px;
          vertical-align: sub;
        }
      }

      .el-table {
        background: #f4f4f5;
        .el-icon-star-off {
          cursor: pointer;
        }
      }
      .el-table::before {
        height: 0;
      }
    }
  }
  .home-content-three {
    background: #f4f4f5;
    text-align: center;
    height: 350px;
    margin-bottom: 100px;
    .point {
      display: flex;
      align-items: center;
      justify-content: center;

      &-item {
        display: flex;
        flex-direction: column;
        width: 389px;
        height: 358px;
        border-radius: 8px;
        background: #fff;
        padding: 16px 32px 32px 32px;
        img {
          width: 140px;
          height: 140px;
          margin: 0 auto 20px;
        }
        &.V2 {
          margin: 0 16px;
        }
        p {
          color: #f28c34;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 10px;
          text-align: center;
          margin: 0 auto;
          width: 280px;
          height: 64px;
          line-height: 32px;
        }

        span {
          font-size: 16px;
          font-weight: 400;
          color: #15192a;
          line-height: 24px;
          margin-top: 10px;
        }
      }

      &-item:last-child {
        margin-right: 0;
      }
    }
  }
  .home-content-four {
    height: 700px;
    text-align: center;
    padding-bottom: 50px;
    background: #f4f4f5;
    .four-content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      // justify-content: space-between;
    }
    .content-title-v2 {
      width: 1132px;
      font-family: DINPro;
      margin: 0 auto;
      text-align: left;
      margin-bottom: 38px;
      span {
        display: block;
        width: 1132px;
        font-size: 18px;
        color: #15192a;
      }
    }
    .content-title {
      font-size: 36px;
      font-weight: 600;
      width: 1132px;
      margin: 0 auto;
      text-align: center;
      padding: 60px 0;
      font-family: DINPro;
      color: #354052;
    }
    .hor-line {
      margin-bottom: 50px;
    }
    .left-content {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      position: relative;
      width: 520px;
      // min-width: 420px;
      // max-width: 500px;
      // margin-left: 80px;
      .tips-details {
        li {
          display: flex;
          i {
            margin-right: 16px;
            color: #f28c34;
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #f28c34;
            vertical-align: middle;
            border-radius: 50%;
            margin-top: 7px;
          }
        }
      }

      .left-content-title {
        font-size: 18px;
        font-weight: 600;
        // width: 20px;
        color: #1f2533;
        margin-bottom: 25px;
      }
      li {
        margin-bottom: 14px;
        color: #354052;
      }
      img {
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
      .download-content {
        margin-top: 40px;
        display: flex;
        position: relative;
        .download-item {
          img {
            width: 150px;
            height: 50px;
          }
        }
        .qr-code {
          img {
            cursor: pointer;
            text-align: center;
            width: 50px;
            height: 50px;
            color: #ffffff;
          }
        }
      }
      .download-code {
        // float: right;
        // margin-right: 60px;
        position: absolute;
        right: 100px;
        box-sizing: border-box;
        width: 120px;
        height: 145px;
        border-color: #eaecef;
        margin-top: 10px;
        background: #ffffff;
        padding: 10px;
        border-radius: 4px;
        z-index: 900;

        img {
          width: 100px;
          height: 100px;
        }
        .scren-code {
          width: 100%;
          margin-top: 5px;
          text-align: center;
          box-sizing: border-box;
          color: #1e2329;
          font-size: 14px;
        }
      }
    }
    .right-content {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 630px;
      height: 370px;
      img {
        width: 548px;
        height: 466px;
      }
    }
  }
  .home-content-five {
    // height: 370px;
    padding: 0px 0 60px;
    background: #f4f4f5;
    .content-title {
      width: 1132px;
      font-family: DINPro;
      font-size: 36px;
      font-weight: 600;
      text-align: left;
      padding-top: 65px;
      margin: 0px auto 22px;
      color: #1f2533;
      .right {
        float: right;
      }
      &.V2 {
        direction: ltr;
      }
      &.V3 {
        margin-bottom: 58px;
      }
    }
    .hor-line {
      margin-bottom: 50px;
    }
    .five-content {
      display: flex;
      justify-content: center;

      .item-content {
        position: relative;
        &.V2 {
          margin-left: 16px;
        }
        img {
          width: 288px;
          height: 288px;
        }
        .item-text {
          position: absolute;
          bottom: 30px;
          left: 0px;
          color: #fff;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          display: inline-block;
          width: 288px;
          text-align: center;
        }
      }
    }
    .five-items-content {
      width: 1132px;
      margin: 0 auto;
      .five-content-items {
        display: inline-block;
        width: 400px;
        height: 280px;
        border: 1px solid #edf1f6;
        padding: 35px 0 0 50px;
        vertical-align: top;

        &.V1 {
          border-right: none;
          border-bottom: none;
        }
        &.V2 {
          border-right: none;
          border-bottom: none;
        }
        &.V3 {
          border-bottom: none;
        }
        &.V4 {
          border-right: none;
        }
        &.V5 {
          border-right: none;
        }
        .right {
          img {
            margin-left: 30px;
            // width: 52px;
            height: 58px;
          }
        }

        .text-title {
          width: 300px;
          font-size: 26px;
          font-weight: bold;
          color: #15192a;
          margin: 20px 0 20px 30px;
          text-align: left;
        }
        .text-item {
          margin-bottom: 22px;
          .pointer {
            border-radius: 50%;
            width: 7px;
            height: 7px;
            margin-right: 20px;
            background-color: #32c8a4;
            display: inline-block;
          }
          span {
            display: inline-block;
            width: 212px;
            font-size: 14px;
            vertical-align: top;
            color: #666;
          }
        }
      }
    }
  }
  .home-content-six {
    background: #F6F7F8;
    .six-content {
      width: 100%;
      min-height: 390px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .six-content-title {
      text-align: center;
      color: var(--Neutral-900, #18181b);
      font-feature-settings: "liga" off, "clig" off;

      /* Desktop Headline/H2-text */
      font-family: "Plus Jakarta Sans";
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 111.111% */
      &.V2 {
        width: 841px;
        margin-top: 40px;
        color: var(--Neutral-900, #18181b);
        font-feature-settings: "liga" off, "clig" off;

        /* Mob/Web/24 Reg */
        font-family: "Plus Jakarta Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 33.6px */
      }
    }
    .regsiter-content {
      margin-top: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        display: inline-block;
        width: 350px;
        height: 50px;
        margin: 0 10px 0 0;
        font-size: 16px;
        line-height: 50px;
        font-weight: 500;
        padding: 12px 0 12px 17px;
        border-radius: 8px;
        background-color: #fff;
      }
      .goRegsiter {
        border-radius: 8px;
        cursor: pointer;
        vertical-align: middle;
        background: #f28c34;
        text-align: center;
        font-size: 16px;
        display: inline-block;
        width: 130px;
        line-height: 46px;
        height: 46px;
        color: #fff;
        box-sizing: border-box;
        margin-top: 0;
      }
    }
  }
  .text-content {
    font-size: 16px;
    bottom: 83px;
    color: #333;
    padding-bottom: 3px;
    text-decoration: underline;
  }
  .dong-show {
    display: flex;
    position: relative;
    // margin-top: 40px;
    .dong a {
      margin-left: 12px;
    }
    .download-item {
      margin-left: 12px;
    }
    .download-code {
      // float: right;
      // margin-right: 60px;
      position: absolute;
      right: 0px;
      box-sizing: border-box;
      width: 120px;
      height: 145px;
      border-color: #eaecef;
      margin-top: 10px;
      background: #ffffff;
      padding: 10px;
      border-radius: 4px;
      z-index: 900;

      img {
        width: 100px;
        height: 100px;
      }
      .scren-code {
        width: 100%;
        margin-top: 5px;
        text-align: center;
        box-sizing: border-box;
        color: #1e2329;
        font-size: 14px;
      }
    }
  }
  .dong {
    min-width: 350px;
    white-space: nowrap;
    height: 70px;
    margin-top: 43px;
    span {
      display: inline-block;
      cursor: pointer;
      margin-right: 15px;
    }
    img.link {
      width: 52px;
      height: 52px;
    }
  }
  .regsiter-content-top {
    margin: 24px 0px;
    background: white;
    display: flex;
    border-radius: 12px;
    box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.06);
    justify-content: space-between;
    align-items: center;
    position: relative;

    input {
      display: inline-block;
      width: 342px;
      height: 46px;
      margin: 0 10px 0 0;
      font-size: 16px;
      line-height: 46px;
      font-weight: 500;
      padding: 12px 0px 12px 17px;
      border-radius: 2px;
      &::placeholder {
        color: #dddee1;
      }
    }
  }
  .go-trade {
    border-radius: 10px;
    cursor: pointer;
    vertical-align: middle;
    background: #f28c34;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    width: 520px;
    line-height: 50px;
    height: 50px;
    color: white;
    box-sizing: border-box;
    margin-right: 5px;
    margin-top: 24px;
  }
  .goRegsiter {
    border-radius: 10px;
    cursor: pointer;
    vertical-align: middle;
    background: #f28c34;
    text-align: center;
    font-size: 16px;
    display: inline-block;
    width: 92px;
    line-height: 50px;
    height: 50px;
    color: white;
    box-sizing: border-box;
    margin-right: 5px;
    margin-top: 24px;
    margin-left: 16px;
  }
  .trade-btn {
    border-radius: 10px;
    cursor: pointer;
    vertical-align: middle;
    background: #f28c34;
    text-align: center;
    font-size: 16px;
    display: inline-block;
    width: 92px;
    line-height: 50px;
    height: 50px;
    color: white;
    box-sizing: border-box;
    margin-top: 24px;
    margin-left: 16px;
  }
  .el-table {
    margin-left: -10px;
    .el-table__header {
      thead {
        .cell {
          font-size: 14px;
          color: #7f8490;
        }
      }
    }
    tbody {
      .operate {
        width: 88px;
        height: 30px;
        padding: 4px 0px;
        text-align: center;
        border-radius: 4px;
        font-weight: 400 !important;
        font-size: 14px;
        background-color: #f28c34 !important;
        color: #fff !important;

        span {
          font-weight: 400 !important;
          color: #fff !important;
        }
        &:hover {
          background-color: #f28c34 !important;
          color: #fff !important;
          span {
            color: white !important;
          }
        }
      }
      .mrket-img {
        height: 30px;
      }

      .cell {
        .coin-content {
          display: inline-block;
          .coin-img {
            width: 30px;
            height: 30px;
            vertical-align: middle;
          }
        }
        .red {
          color: #c61b3d;
        }
        .green {
          color: #03ad79;
        }
        .active {
          color: #c61b3d;
          font-size: 18px;
          margin-top: 4px;
          margin-left: -3px;
        }
        .el-icon-star-on,
        .el-icon-star-off {
          text-align: left;
          cursor: pointer;
          margin-right: 10px;
        }
        p,
        span,
        code {
          color: #1f2533;
          // font-weight: 600;
        }
        .el-table__expand-icon {
          display: none;
        }
        .dark-color {
          color: #9aa1b1;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .table-expand {
      min-height: 228px;
      padding: 10px;
      display: flex;
      cursor: auto;
      .expand-detail {
        flex: 1;
        display: inline-block;
        margin-right: 32px;
      }
      .el-form {
        display: inline-block;
        width: 300px;
        .el-form-item {
          margin-bottom: 0px;
          padding: 0 20px;
          .el-form-item__label {
            padding: 0;
          }
          &:nth-child(2n + 1) {
            background-color: #f5f5f7;
          }
        }
        .el-form-item__content {
          display: inline-block;
          float: right;
        }
      }
    }
  }
  .el-tabs__item {
    font-size: 16px;
    margin-bottom: 10px;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #bec1c8 !important;
    font-weight: 500;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #bec1c8 !important;
    font-weight: 500;
  }
}
.home-content-partners {
  min-height: 402px;
  background: #F6F7F8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .hcp-title {
    width: 1132px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    text-align: center;
    .ht-main {
      color: var(--Neutral-900, #18181B);
      font-feature-settings: 'liga' off, 'clig' off;
      
      /* Desktop Headline/H2-text */
      font-family: "Plus Jakarta Sans";
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 111.111% */
    }
    .ht-sub {
      margin-top: 18.5px;
      font-size: 18px;
      color: #15192a;
    }
  }
  .hcp-content {
    width: 1132px;
    margin: 0 auto;
    padding: 25px 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;

    .hc-imgs {
      width: 227px;
      height: 122px;
      display: flex;
      flex-wrap: wrap;
      background: #fff;
      border-radius: 8px;
      &.V2 {
        margin-left: 16px;
      }
      .img-c {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.text-content {
  text-transform: capitalize;
}
.home-content-coin {
  background: #fff;
  .content {
    width: 1132px;
    margin: 0 auto;
    display: flex;
    gap: 120px;
    flex-direction: column;
    align-items: flex-start;
    min-height: 1109px;
    padding-bottom: 60px;
    .oe-coin {
      margin-top: 80px;
      width: 100%;
      height: 280px;
      flex-shrink: 0;
      border-radius: 8px;
      background: url("../../assets/img/ueex/home/oe-coin-bg.svg") no-repeat,
        #18181b;
      background-position: bottom;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 80px;
      padding-right: 40px;
      .top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .left {
          .coin-top-title {
            color: #fff;
            text-align: start;
            font-feature-settings: "liga" off, "clig" off;
            font-family: "Plus Jakarta Sans";
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 100% */
          }
          .coin-top-text {
            margin-top: 20px;
            color: #c5cad5;
            text-align: start;
            font-feature-settings: "liga" off, "clig" off;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px; /* 100% */
          }
        }
      }
      .btm {
        margin-top: 80px;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        gap: 145px;
        .item {
          .btm-item-title {
            color: #fff;
            font-feature-settings: "liga" off, "clig" off;
            font-family: "Plus Jakarta Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 100% */
          }
          .btm-item-text {
            margin-top: 20px;
            color: #c5cad5;
            font-feature-settings: "liga" off, "clig" off;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px; /* 100% */
          }
        }
      }
    }
    .faq {
      width: 100%;
      .title {
        color: #18181b;
        text-align: center;
        font-feature-settings: "liga" off, "clig" off;
        font-family: "Plus Jakarta Sans";
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 100% */
      }
      .fap-collapse {
        margin-top: 40px;
      }
    }
  }
}
.el-collapse-item__header {
  .el-icon-arrow-right {
    display: none;
  }
}
.custom-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.custom-icon {
  font-size: 1.5em;
  margin-right: 10px;
}
</style>
