<template>
  <div class="ueex-careers-page">
    <div class="careers-content-one">
      <dv class="content">
        <img src="../../assets/img/ueex/careers/coming-soon.svg" alt="">
        <div class="title">
          {{ translateTitle("Openings coming soon") }}
        </div>
      </dv>
    </div>
  </div>
</template>

<script>
import commonMixin from "@/components/common/commonMixin.vue";
export default {
  name: "Careers",
  mixins: [commonMixin],
}

</script>

<style lang="scss">
.ueex-careers-page {
  .careers-content-one {
    background: #FFF;
    .content {
      width: 1132px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 422px;
      img {
        width: 200px;
        height: 200px;
        flex-shrink: 0;
      }
      .title {
        margin-top: 20px;
        color: #858B9C;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 100% */
      }
    }
  }
}
</style>