import request from "./index";

// const key = 'https://www.metalifegame.org/api'; // 生产
// const key = ''; // 测试

// const api = http[key] || http.instance(key); // api modules.test

class Service {
    constructor() {
        this.api = request;
    }

    // eslint-disable-next-line class-methods-use-this
    buy(params) {
        return request.get("/api/buy", { params });
    }

    // post请求
    // eslint-disable-next-line class-methods-use-this
    postRequest(params) {
        return request.post("/api/url", params);
    }

    getByCode(params) {
        // 校验code
        return this.api.get("/agent/getByCode", { params });
    }

    agentRecordInit(params) {
        return this.api.post("/agentRecord/init", params);
    }

    agentRecordAdd(params) {
        return this.api.post("/agentRecord/add", params);
    }
    // 公共表格数据请求方法
    // publicGet(url, params) {
    //   return request.get(url, {
    //     params
    //   })
    // }

    // 公共post方法
    // publicPost(url, params) {
    //   return request.post(url, params)
    // }
}
export default new Service();

// 查询投票活动
export async function voteGetVote(params) {
    return request.get("/ueexApi/api/v1/vote/getVote", params);
}

// 查询抽票地址
export async function voteGetAddress(params) {
    return request.get("/ueexApi/api/v1/vote/getAddress", params);
}

// 查询昨日榜首文章
export async function getYesterdayData(params) {
    return request.get("/ueexApi/api/v1/vote/findArticle", params);
}
