<template>
    <div class="vote-for-listing-page">
        <div class="activity-content-one">
            <div class="content">
                <div class="left">
                    <div class="activity-sign">
                        <div class="top">
                            {{ translateTitle("Vote to List Your Desired Tokens") }}
                        </div>
                        <div class="mid">
                            {{ translateTitle("SHARE HUGE REWARDS") }}
                        </div>
                    </div>
                </div>
                <div class="right" style="cursor: pointer">
                    <img src="../../assets/img/ueex/vote-for-listing/one.png" />
                </div>
            </div>
        </div>
        <div class="activity-content-two">
            <div :class="['content', isWarmupStarted ? '' : 'content-s-t']">
                <div class="main">
                    <div class="top">
                        <el-button class="vote-two-btn" @click="handleToVote">
                            <!-- <img src="../../assets/img/ueex/vote-for-listing/two-btn-left.svg" /> -->
                            {{ translateTitle("如何参与及为支持项目投票") }}
                            <img src="../../assets/img/ueex/vote-for-listing/two-btn-right.svg" />
                        </el-button>
                    </div>
                    <div :class="['bottom', isWarmupStarted ? '' : 'bottom-s-t']">
                        <template v-if="isWarmupStarted">
                            <div class="bottom-item">
                                <div class="y-content">
                                    <div class="round">
                                        <img src="../../assets/img/ueex/vote-for-listing/two-round.svg" alt="">
                                    </div>
                                    <div class="y-text">
                                        <div class="title">
                                            {{ translateTitle("活动预热") }}
                                        </div>
                                        <div class="time">
                                            {{ timestamp(+voteData.warmupTime / 1000, "yyyy-MM-dd HH:mm:ss") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                            </div>
                            <div class="bottom-item">
                                <div class="y-content">
                                    <div class="round">
                                        <img src="../../assets/img/ueex/vote-for-listing/two-round.svg" alt="">
                                        <div class="y-text">
                                            <div class="title">
                                                {{ translateTitle("开始投票") }}
                                                <!-- <el-tooltip class="item" effect="dark" content="Top Center 提示文字"
                                                popper-class="tooltip-dark">
                                                <img src="../../assets/img/ueex/vote-for-listing/hint.svg" alt="">
                                            </el-tooltip> -->
                                            </div>
                                            <div class="time">
                                                {{ timestamp(+voteData.voteBeginTime / 1000, "yyyy-MM-dd HH:mm:ss") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                            </div>
                            <div class="bottom-item">
                                <div class="y-content">
                                    <div class="round">
                                        <img src="../../assets/img/ueex/vote-for-listing/two-round.svg" alt="">
                                        <div class="y-text">
                                            <div class="title">
                                                {{ translateTitle("投票截止") }}
                                            </div>
                                            <div class="time">
                                                {{ timestamp(+voteData.endTime / 1000, "yyyy-MM-dd HH:mm:ss") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="stay-tuned">
                                <img src="../../assets/img/ueex/vote-for-listing/stay-tuned.svg" alt="">
                                {{ translateTitle("Stay tuned") }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isWarmupStarted" class="activity-content-three">
            <div class="content">
                <div class="main">
                    <!-- <div class="top">
                        {{ translateTitle('仅展示已投票') }}
                        <el-switch v-model="value1" />
                    </div> -->
                    <div class="hint">
                        {{ translateTitle("At the end of the event, the top three projects by vote count will have the opportunity to be listed on UEEx.") }}
                    </div>
                    <div class="table ranking-table-list">
                        <el-table v-loading="tableLoading" :data="tableDataComputed" row-key="symbol_id"
                            style="width: 100%" class="trade-table">
                            <el-table-column :label="translateTitle('项目')" align="left" min-width="140" prop="name">
                                <template slot-scope="{ row, $index }">
                                    <span class="coin-content" style="cursor: pointer">
                                        <div class="ranking">
                                            <img v-if="$index == 0" src="@/assets/img/ueex/vote-for-listing/LV1.svg" alt="">
                                            <img v-else-if="$index == 1" src="@/assets/img/ueex/vote-for-listing/LV2.svg" alt="">
                                            <img v-else-if="$index == 2" src="@/assets/img/ueex/vote-for-listing/LV3.svg" alt="">
                                            <template v-else>
                                                {{ $index + 1 }}
                                            </template>
                                        </div>
                                        <img class="coin-img" :src="row.logo" alt="" />
                                        <span class="left-symbol" style="margin-left: 2px; font-size: 16px">{{
                                            row.shortName }}</span>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="translateTitle('初始价格(单位)')" align="left" prop="initPrice"
                                min-width="140">
                                <template slot-scope="{ row }">
                                    <div>
                                        <span class="DINPro left-row">
                                            {{ formatNumberRgx(row.initPrice) }}
                                        </span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="translateTitle('Votes')" align="center" prop="countVote"
                                min-width="140">
                                <template slot-scope="{ row }">
                                    <span class="left-row DINPro">{{ formatNumberRgx(row.countVote) }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column :label="translateTitle('Count')" align="center" prop="countNumber"
                                min-width="140">
                                <template slot-scope="{ row }">
                                    <span class="left-row DINPro">{{ formatNumberRgx(row.countNumber || 0) }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column :label="translateTitle('操作')" class="operate-column" align="right"
                                min-width="90">
                                <template slot-scope="{ row }">
                                    <el-button :disabled="!isVotingStarted||voteData.voteStatus==2" :style="{ opacity: (isVotingStarted||voteData.voteStatus==2) ? 1 : 0.5 }"
                                        @click="getAddress(row.voteProjectId)" class="operate">{{
                                            translateTitle("投票")
                                        }}</el-button>
                                </template>
                            </el-table-column>
                            <no-data slot="empty" v-show="!tableLoading && !tableDataComputed.length"></no-data>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="v8show" :show-close="true" :lock-scroll="true" :close-on-click-modal="true"
            :modal-append-to-body="true" custom-class="v8kyc" center width="480px">
            <div class="v8-content">
                <div class="v8-title">
                    {{ translateTitle("Note: Please recharge your UE coins to this address. After the recharge is successful, it will be automatically converted into voting points.") }}
                </div>
                <div class="qr-code" ref="qrCodeUrl"></div>
                <div class="qr-code-text">
                    {{ address }}
                    <i class="iconfont iconicon-copy" v-clipboard:copy="address" v-clipboard:success="copySuccess"
                        v-clipboard:error="copyError"></i>
                </div>

                <el-button @click="v8show = false, u8show = true" class="operate">{{ translateTitle("我已投票")
                    }}</el-button>
            </div>
        </el-dialog>
        <!-- 投票完成 -->
        <el-dialog :visible.sync="u8show" :show-close="true" :lock-scroll="true" :close-on-click-modal="true"
            :modal-append-to-body="true" custom-class="v8kyc" center width="480px">
            <div class="u8-content">
                {{ translateTitle("投票确认中") }}
                <div class="line"></div>
                <el-button class="u8-btn" @click="u8show = false" type="text">{{ translateTitle("我知道了")
                    }}</el-button>
            </div>
        </el-dialog>
        <!-- 昨日榜首 -->
        <el-dialog :visible.sync="isHead" :show-close="true" :lock-scroll="true" :close-on-click-modal="true"
            :modal-append-to-body="true" custom-class="v8kyc" center width="1000px" :title="yesterday.title">
            <div style="max-height:600px;overflow-y: auto;">
                <div v-if="yesterday" v-html="yesterday.content"></div>
            </div>

            <el-divider />
            <div style="text-align:center;color: #f28c34;cursor: pointer;">
                <span @click="isHead = false">{{ translateTitle('我知道了') }}</span>
            </div>

        </el-dialog>
    </div>

</template>


<script>
import QRCode from 'qrcodejs2';
import commonMixin from "@/components/common/commonMixin.vue";
import NoData from "@/components/common/NoData";
import { voteGetVote, voteGetAddress,getYesterdayData } from "@/apiVoteForListing/api";
import {
    timestamp,
} from "@/assets/js/formatDate";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
    name: "AssetActivity",
    mixins: [commonMixin],
    components: {
        NoData,
    },
    computed: {
        ...mapGetters({
            language: "user/language",
        }),
        // 判断预热是否开始
        isWarmupStarted() {
            const now = Math.floor(this.currentTime);
            return now >= Number(this.voteData.warmupTime);
        },
        // 判断预热是否在进行中
        isActivityStarted() {
            const now = Math.floor(this.currentTime);
            return now >= Number(this.voteData.warmupTime) && now <= Number(this.voteData.voteBeginTime);
        },
        // 判断投票是否在进行中
        isVotingStarted() {
            const now = Math.floor(this.currentTime);
            return now >= Number(this.voteData.voteBeginTime) && now <= Number(this.voteData.endTime);
        },
        // 判断投票是否结束
        isVotingEnded() {
            const now = Math.floor(this.currentTime);
            return now >= Number(this.voteData.endTime);
        },
    },
    data() {
        return {
            // 当前时间戳
            currentTime: Date.now(),
            timer: null,
            tableLoading: false,
            v8show: false,
            u8show: false,
            // 当前状态
            currentStatus: 0,
            tableDataComputed: [
            ],
            voteData: {
                endTime: '',
                name: '',
                projectList: [],
                remark: '',
                voteBeginTime: '',
                voteId: '',
                voteStatus: 0,
                warmupTime: ''
            },
            address: '',
            isHead: false,
            yesterday:{
                content:'',
                title:''
            }
        }
    },
    methods: {
        timestamp,
        /* 创建二维码 */
        creatQrCode(address = '') {
            // 清除上一次的二维码
            this.$refs.qrCodeUrl.innerHTML = '';
            new QRCode(this.$refs.qrCodeUrl, {
                text: address, // 二维码的内容
                width: 240,
                height: 240,
                colorDark: '#000',
                colorLight: '#fff',
                correctLevel: QRCode.CorrectLevel.H
            });
        },
        handleToVote() {
            let url = '';

            if (this.language == 'zh_TW') {        
                url = 'https://support.ueex.com/hc/zh-tw/articles/11617982757903-UEEx%E6%8A%95%E7%A5%A8%E4%B8%8A%E5%B9%A3-%E5%A6%82%E4%BD%95%E5%8F%83%E8%88%87%E5%8F%8A%E7%82%BA%E6%94%AF%E6%8C%81%E9%A0%85%E7%9B%AE%E6%8A%95%E7%A5%A8';
                
            } else if ('zh_CN'.includes(this.language)) {     
                url = 'https://support.ueex.com/hc/zh-cn/articles/11617982757903-UEEx%E6%8A%95%E7%A5%A8%E4%B8%8A%E5%B8%81-%E5%A6%82%E4%BD%95%E5%8F%82%E4%B8%8E%E5%8F%8A%E4%B8%BA%E6%94%AF%E6%8C%81%E9%A1%B9%E7%9B%AE%E6%8A%95%E7%A5%A8';
            } else {
                url = 'https://support.ueex.com/hc/en-us/articles/11617982757903-UEEx-Coin-Listing-Vote-How-to-Participate-and-Support-Your-Project';
            }
            
            window.open(url, '_blank');
        },
        copySuccess() {
            this.$message.success('Copy Success');
        },
        copyError() {
            this.$message.error('Copy Failure');
        },
        async initVoteData() {
            const res = await voteGetVote();
            if (res) {
                this.voteData = res;
                this.tableDataComputed = res?.projectList;
                this.getActivityProgress()
            } else {
                this.voteData.warmupTime = '1745728000000';
            }

            // 活动结束时间 2024-02-01 00:00:00
            // endTime: '1706945599',
            // // 活动预热时间 2023-12-24 16:00:00
            // warmupTime: '1704038400',
            // // 投票开始时间 2024-01-01 00:00:00
            // voteBeginTime: '1706745599',
            // 活动结束时间 2024-02-01 00:00:00
            // endTime: '1735928000',
            // 活动预热时间 2025-01-01 16:00:00
            // warmupTime: '1735728000',
            // 投票开始时间 2024-01-01 00:00:00
            // voteBeginTime: '1735828000',
            // this.voteData = {
            //     // 活动预热时间 2024-12-30 19:51:00
            //     warmupTime: '1738565700000',
            //     // 投票开始时间 2024-12-30 19:52:00
            //     voteBeginTime: '1739565760000',
            //     // 活动结束时间 2024-12-30 19:53:00
            //     endTime: '1734065820000',
            //     name: 'Community Token Vote',
            //     projectList: [
            //         {
            //             countNumber: 0,
            //             countVote: '0',
            //             initPrice: '0.001',
            //             logo: 'https://example.com/logo1.png',
            //             name: 'Project A',
            //             shortName: 'PA',
            //             symbol: 'PA',
            //             voteProjectId: 'proj_001'
            //         },
            //         {
            //             countNumber: 0,
            //             countVote: '0',
            //             initPrice: '0.002',
            //             logo: 'https://example.com/logo2.png',
            //             name: 'Project B',
            //             shortName: 'PB',
            //             symbol: 'PB',
            //             voteProjectId: 'proj_002'
            //         }
            //     ],
            //     remark: 'Vote for your favorite project',
            //     voteId: 'vote_123',
            //     voteStatus: 3, // 3 means voting in progress
            // };
            // this.tableDataComputed = this.voteData.projectList;
            // this.getActivityProgress()
        },
        // 判断活动进度
        getActivityProgress() {
            if (this.isVotingEnded) {
                clearTimeout(this.timer);
                console.error('投票结束')
                return '投票结束';
            }
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.currentTime = Date.now();
                this.getActivityProgress()
            }, 1000);
        },
        async getAddress(voteProjectId) {
            const res = await voteGetAddress({ voteProjectId });
            // const res = {
            //     address: 'https://blog.csdn.net/AdminGuan'
            // };
            this.v8show = true;
            if (res) {
                this.address = res.address;
                setTimeout(() => {
                    this.creatQrCode(res.address);
                }, 100);
            } else {
                this.address = '';
                setTimeout(() => {
                    this.creatQrCode('');
                }, 100);
            }
        },
        async headShow() {
            const prevTime = localStorage.getItem('vote-date-time');

            if(!prevTime || dayjs(+prevTime).isBefore(dayjs(), 'day')) {
                const result = await getYesterdayData();

                if(result){
                    this.yesterday = result;
                    this.isHead = true;
                    localStorage.setItem('vote-date-time', dayjs().valueOf());
                }
               
            }
        }
    },
    async mounted() {
        this.initVoteData();
        // 昨日榜首
        this.headShow();
    },
    beforeDestroy() {
        clearTimeout(this.timer);
    },
    watch: {
    }
};
</script>


<style lang="scss">
.ar .vote-for-listing-page .activity-content-five .five-items-content .five-content-items {
    padding: 35px 50px 0 0;
}

.en .vote-for-listing-page .activity-content-one .activity-sign {
    top: 54px;

    .top {
        // font-family: DIN;
        font-family: "Plus Jakarta Sans";
        font-weight: bold;
    }

    .middle {
        // font-family: DIN;
        font-size: 43px;
        font-weight: bold;
    }
}

.vote-for-listing-page {
    margin-top: -2px;
    // height: 2977px;
    display: block;
    background: #f4f4f5;
    // user-select: none;
    padding: 0;
    flex: 1;

    [v-cloak] {
        display: none;
    }

    .fiv-svg {
        position: relative;

        i {
            z-index: 900;
            position: absolute;
            font-size: 60px;
            top: -2px;
            left: 110px;
        }
    }

    .hor-line {
        width: 74px;
        height: 6px;
        border-radius: 3px;
        background-image: linear-gradient(89deg, #00cf88 0%, #0084d6 100%);
        margin: 0 auto;
        display: block;
    }

    // font-family: PingFangSC, BinancePlex, Arial, sans-serif !important;
    .is-resiger {
        height: 340px;
    }

    .no-resiger {
        height: 340px;
    }

    .swiper-img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
    }

    .activity-content-one {
        background: url("../../assets/img/ueex/vote-for-listing/one-bg.svg") lightgray 50% / cover no-repeat;

        .content {
            width: 1180px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 360px;
        }

        .left {
            width: 60%;
            text-align: left;
        }

        .right {
            img {
                width: 340px;
                height: 340px;
            }
        }

        // img {
        //   height: 400px;
        // }
        .coin-ico {
            position: absolute;
            // animation: b 1.5s ease-in-out 2s infinite alternate;

            &.usdt {
                animation: bounce-down 1s ease-in-out 2s infinite alternate;
                bottom: 10%;
                right: 20%;
            }

            &.btc {
                animation: bounce-down 1.3s ease-in-out 2s infinite alternate;
                top: 5%;
                right: 24%;
            }

            &.eth {
                // animation: b 0.5s ease-in-out 0.5s infinite alternate;
                animation: bounce-down 1.6s ease-in-out 0.5s infinite alternate;
                bottom: 20%;
                left: 15%;
            }
        }

        .activity-sign {
            width: 100%;

            .top {
                color: #18181B;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: "Plus Jakarta Sans";
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                /* 100% */
            }

            .mid {
                margin-top: 24px;
                color: #18181B;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: "Plus Jakarta Sans";
                font-size: 52px;
                font-style: normal;
                font-weight: 700;
                line-height: 52px;
                /* 100% */
            }
        }

        .register-btn {
            cursor: pointer;
            height: 54px;
            padding: 13px 150px;
            border-radius: 50px;
            background-image: linear-gradient(87deg, #00cf88 0%, #0084d6 100%);
            font-size: 20px;
            font-weight: 500;
            color: #fff;
            text-align: center;
            position: absolute;
            bottom: 60px;
            left: calc(50% - 210px);
        }
    }

    .activity-content-two {
        background: #F6F7F8;

        .content {
            width: 1180px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 160px;
            position: relative;

            .main {
                width: 100%;
                position: absolute;
                top: -100px;

                .top {
                    height: 78px;
                    border-radius: 12px 12px 0px 0px;
                    background: #18181B;
                    display: flex;
                    align-items: center;
                    padding: 0 28px;
                    gap: 20px;

                    .vote-two-btn {
                        border-radius: 1000px;
                        border: 1px solid #FFF;
                        height: 38px;
                        padding: 12px 20px;
                        background: transparent;
                        color: #FFF;
                        font-feature-settings: 'liga' off, 'clig' off;
                        font-family: "Plus Jakarta Sans";
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 14px;

                        /* 100% */
                        >span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 8px;
                        }
                    }
                }

                .bottom {
                    padding-top: 28px;
                    height: 140px;
                    border-radius: 0px 0px 12px 12px;
                    background: #FFF;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    gap: 12px;

                    .stay-tuned {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        gap: 20px;
                        color: #858B9C;
                        text-align: center;
                        font-feature-settings: 'liga' off, 'clig' off;
                        font-family: "Plus Jakarta Sans";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14px;
                        /* 100% */
                    }

                    .bottom-item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 12px;

                        .y-content {
                            height: 100%;
                            background: #F6F7F8;
                            position: relative;

                            .y-text {
                                display: flex;
                                flex-direction: column;
                                gap: 12px;
                                width: max-content;
                                position: absolute;
                                top: 42px;
                                left: 0;
                                color: #18181B;
                                font-feature-settings: 'liga' off, 'clig' off;
                                font-family: "Plus Jakarta Sans";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 16px;

                                /* 100% */
                                .title {
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                }
                            }
                        }

                        .line {
                            width: 341px;
                            height: 1px;
                            background: #18181B;
                        }
                    }
                }

                .bottom-s-t {
                    min-height: 300px;
                }
            }
        }

        .content-s-t {
            min-height: 380px;
        }
    }

    .activity-content-three {
        background: #F6F7F8;
        padding-bottom: 100px;

        .content {
            width: 1180px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            min-height: 504px;
            border-radius: 12px;
            background: #FFF;
            padding: 21px 28px;

            .main {
                width: 100%;

                .top {
                    display: flex;
                    justify-content: flex-end;
                    gap: 12px;
                    color: #999;
                    font-feature-settings: 'liga' off, 'clig' off;
                    font-family: "Plus Jakarta Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    /* 100% */
                }

                .hint {
                    margin-top: 21px;
                    color: #999;
                    font-feature-settings: 'liga' off, 'clig' off;
                    font-family: "Plus Jakarta Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    /* 100% */
                }

                .table {
                    margin-top: 40px;
                }
            }
        }
    }

    .el-table {
        margin-left: -10px;

        .el-table__header {
            thead {
                .cell {
                    font-size: 14px;
                    color: #7f8490;
                }
            }
        }

        tbody {

            .mrket-img {
                height: 30px;
            }

            .cell {
                .coin-content {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    .coin-img {
                        width: 30px;
                        height: 30px;
                        vertical-align: middle;
                    }
                    .ranking {
                        width: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 9px;
                    }
                }

                .red {
                    color: #c61b3d;
                }

                .green {
                    color: #03ad79;
                }

                .active {
                    color: #c61b3d;
                    font-size: 18px;
                    margin-top: 4px;
                    margin-left: -3px;
                }

                .el-icon-star-on,
                .el-icon-star-off {
                    text-align: left;
                    cursor: pointer;
                    margin-right: 10px;
                }

                p,
                span,
                code {
                    color: #1f2533;
                    // font-weight: 600;
                }

                .el-table__expand-icon {
                    display: none;
                }

                .dark-color {
                    color: #9aa1b1;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }

        .table-expand {
            min-height: 228px;
            padding: 10px;
            display: flex;
            cursor: auto;

            .expand-detail {
                flex: 1;
                display: inline-block;
                margin-right: 32px;
            }

            .el-form {
                display: inline-block;
                width: 300px;

                .el-form-item {
                    margin-bottom: 0px;
                    padding: 0 20px;

                    .el-form-item__label {
                        padding: 0;
                    }

                    &:nth-child(2n + 1) {
                        background-color: #f5f5f7;
                    }
                }

                .el-form-item__content {
                    display: inline-block;
                    float: right;
                }
            }
        }
    }

    .el-tabs__item {
        font-size: 16px;
        margin-bottom: 10px;
    }

    input::-moz-placeholder {
        /* Firefox 19+ */
        color: #bec1c8 !important;
        font-weight: 500;
    }

    input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #bec1c8 !important;
        font-weight: 500;
    }

    input:-ms-input-placeholder {
        /* IE 10+ */
        color: #bec1c8 !important;
        font-weight: 500;
    }

    input:-moz-placeholder {
        /* Firefox 18- */
        color: #bec1c8 !important;
        font-weight: 500;
    }
}

.el-switch.is-checked .el-switch__core {
    border-color: #f28c34 !important;
    background-color: #f28c34 !important;
}

.operate {
    width: 88px;
    height: auto;
    padding: 4px 0px;
    text-align: center;
    border-radius: 4px;
    font-weight: 400 !important;
    font-size: 14px;
    background-color: #f28c34 !important;
    color: #fff !important;

    span {
        font-weight: 400 !important;
        color: #fff !important;
    }

    &:hover {
        background-color: #f28c34 !important;
        color: #fff !important;

        span {
            color: white !important;
        }
    }
}


.v8-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .v8-title {
        color: #18181B;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-align: center; 
        word-break: break-word;
    }

    .qr-code {
        padding: 10px;
        margin-top: 20px;
        background-color: #fff;
        width: 240px;
        height: 240px;
    }

    .qr-code-text {
        margin-top: 20px;
        border-radius: 8px;
        background: #F6F7F8;
        height: 54px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 0 20px;

    }

    .operate {
        width: 100%;
        margin-top: 20px;
        height: 54px;
    }
}

.u8-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #111A34;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

    .line {
        margin-top: 40px;
        width: calc(100% + 50px);
        transform: translateX(0);
        height: 1px;
        flex-shrink: 0;
        border-radius: 8px;
        background: #EEE;
    }

    /* 100% */
    .u8-btn {
        margin-top: 20px;
        width: 100%;
        color: #F28C34 !important;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding: 0;
        /* 100% */
    }
}
.el-table th.el-table__cell {
    background-color: #F7F8FB !important;
    overflow: hidden;
}
.el-table th.el-table__cell:nth-child(1) {
    border-radius: 12px 0px 0px 0px;
}

.el-table th.el-table__cell:nth-child(5) {
    border-radius: 0px 12px 0px 0px;
}
.ranking-table-list .el-table__row:nth-child(1) {
    background-color: rgba(255, 206, 34, 0.2);
}
.ranking-table-list .el-table__row:nth-child(2) {
    background-color: rgba(158, 194, 247, 0.2);
}
.ranking-table-list .el-table__row:nth-child(3) {
    background-color: rgba(255, 187, 124, 0.2);
}
th {
    Text-Transform: Capitalize;
}
</style>
